<template>
<CContainer class="login-container d-flex flex-column content-center min-vw-100 min-vh-100 mx-0">
    <CRow class="mt-20">
        <CCol>
            <CCardGroup>
                <CCard class="p-2 login-form-container" style="margin-top:40%">
                    <CCardBody>
                        <CForm @submit.prevent @submit="doForgotPassword" autocomplete="off">
                            <h4 class="text-left mb-20">Lupa Password?</h4>
                            <div :class="usernameFilled? 'd-none':''" class="help-block text-danger text-left small font-italic">Username wajib diisi.</div>
                            <CInput v-model="username" placeholder="Username">
                                <template #prepend-content><font-awesome-icon icon="user"/></template>
                            </CInput>
                            <div :class="emailFilled? 'd-none':''" class="help-block text-danger text-left small font-italic">Password wajib diisi.</div>
                            <CInput v-model="email" placeholder="Email" type="email">
                                <template #prepend-content><font-awesome-icon icon="at"/></template>
                            </CInput>
                            <p class="text-left text-muted small">
                                <font-awesome-icon icon="info-circle" class="text-info mr-1"/> 
                                Pastikan anda mengisi data berikut dengan benar. Password baru akan kami kirimkan melalui Email.
                            </p>
                            <CRow>
                                <CCol col="6" class="text-left">
                                    <CLink @click="redir('/login')" color="link" class="px-0" style="line-height:2.2">Masuk?</CLink>
                                </CCol>
                                <CCol col="6" class="text-right">
                                    <CButton type="submit" color="danger" class="px-4 text-white">Kirim</CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCardGroup>
        </CCol>
    </CRow>
</CContainer>
</template>

<script>
const logo = require('@/assets/img/logo.png')
import {
    createNamespacedHelpers,
    mapState,
    mapGetters
} from 'vuex'

export default {
    name: "Login",
    data() {
        return {
            logo:logo,
            username: '',
            usernameFilled: true,
            email: '',
            emailFilled: true,
            process: false
        }
    },
    methods: {
        doForgotPassword(){
            this.usernameFilled = true
            this.emailFilled = true
            if(this.username == ''){
                this.$nextTick(() => this.usernameFilled = false)
            }else if(this.email == ''){
                this.$nextTick(() => this.emailFilled = false)
            }else if(this.username != '' && this.email != '' ){
                this.process = true
                setTimeout( ()=>{    
                    this.$store.dispatch('auth/forgotPassword', {username:this.username, email:this.email}).then( (res) => {
                        if(res == true){                
                            this.username = ''
                            this.email = ''
                            this.$toasted.success('Berhasil mengirim data, silahkan periksa Email Anda!').goAway(3000)
                        }else if(res == false){
                            this.$toasted.error('Gagal mengirim data, periksa kembali username/email Anda').goAway(3000)
                        }else {
                            this.$toasted.error('Gagal mengirim data, silahkan coba lagi!').goAway(3000)
                        }
                        
                        this.process = false
                    })
                },500)
            }

        },
        redir (message) {
			if(this.$route.path != message){
				this.$router.push(message)
			}
		}
    }
}
</script>

<style scoped>
.container{
    padding: 20px;
}
.brand-img{
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
}
.login-form-container{
    width: 400px!important;
}
</style>